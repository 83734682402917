export const CertType = {
  CODECERT: "证据查询",
  FILECERT: "文件校验",
};

export const hideShow3Ways = [30,40,45,50];

/** 数据知识产权存证 id */
export const DataIntellectualPropertyCertId = 45


export const  EEvidenceTypeMap = {
  'WEB_PAGE': 'WEB网页取证',
  'PROCESS': 'WEB录屏取证',
  'PROCESS_APP': 'APP录屏取证',
  'SCENE': 'APP现场取证',
  'VOICE': 'APP录音取证',
  'FILE_PRESERVE': 'WEB数据保全',
  'APP_PHOTO': 'APP拍照取证',
}

