import React, { Component } from "react";
import axios from "axios";
import CryptoJS from "crypto-js";
import { Link } from "react-router-dom";
import {
  SectionsContainer,
  Section,
  Header,
  message,
} from "../../Components/Fullpage";
import QRCode from "qrcode.react";
import { arrayBufferToWordArray, progressiveRead,getBaseUrl } from "../../utils/hashTools";
import { firstSixHash } from "../../utils/mock";
import "./index.less";
import logo from "../../Assets/logo.png";
import hp_seq1 from "../../Assets/hp-seq1.png";
import hp_seq2 from "../../Assets/hp-seq2.png";
import hp_seq3 from "../../Assets/hp-seq3.png";
import hp_seq4 from "../../Assets/hp-seq4.png";
import center from "../../Assets/center.png";
import business from "../../Assets/business.png";
import platform1 from "../../Assets/platform1.png";
import platform2 from "../../Assets/platform2.png";
import certificates from "../../Assets/certificates.png";
import appBanner from "../../Assets/appBanner.png";
import "antd/lib/button/style/index.css";
import { getApiKeys } from "../../utils/requestSign";

class OfficialWebsite extends Component {
  timeout = null;
  state = {
    user: null,
    mode: "light",
    contractNumber: 0,
    safeReportNumber: 0,
    userNumber: 0,
    vulnerabilityNumber: 0,
    qrcode: void 0,
    showRes: false,
  };

  componentDidMount() {
    if (window.location.hash === "#index" || window.location.hash === "") {
      const indexAnchor = document.getElementById("index_anchor");
      indexAnchor.setAttribute("class", "active");

      const navAchor = document.getElementsByClassName("Navigation-Anchor")[0];
      navAchor.setAttribute("class", "Navigation-Anchor active");
    } else window.location = window.location.origin;

    window.addEventListener("load", this.handleOnload);
    this.renderQRcode();
  }
  componentWillUnmount() {
    window.removeEventListener("load", this.handleOnload);
    clearTimeout(this.timeout);
  }

  handleOnload = () => {
    if (sessionStorage.getItem('anchorName')) {

      window.location.hash = "#" + sessionStorage.getItem('anchorName');
      sessionStorage.removeItem('anchorName');
    }
    if (window.location.hash === "") {
      window.location.hash = "#index";
    } else {
      const scrollToAnchor = () => {
        const hashParts = window.location.hash.split("#");

        if (hashParts.length > 2) {
          const hash = hashParts.slice(-1)[0];

          document.querySelector(`#${hash}`).scrollIntoView();
        }
      };

      scrollToAnchor();

      window.onhashchange = scrollToAnchor;
    }

    const navigation = document.getElementsByClassName("Navigation")[0];
    navigation.setAttribute("class", "Navigation iconfont light");
  };

  renderQRcode = async () => {
    axios
      .get("/mobile/version/getAppVersion", {
        baseURL: getBaseUrl(),
        headers: {
          os: 0,
          ...getApiKeys()
        },
      })
      .then((res) => {
        // this.props.showSuccess();
        if (res && res.data.code === 200) {
          const appAddress = res.data.data.appUrl;

          this.setState({ qrcode: appAddress });
        }
      })
      .catch(() => { });
  };

  checkHash = async (value) => {
    try {
      // const { data } = await axios.get(`/v1/verify/cert?firstSixHash=${value}`);
      const data = firstSixHash;
      if (data.code !== 200) {
        message.error(data?.message);
        throw new Error(data.message);
      }
      this.setState({ checkRes: data.data, showRes: true });
      if (data.data.type === 3 || 4) {
        const contentObj = JSON.parse(data.data.content);
        this.setState({
          checkResContent: contentObj,
          dto: contentObj.requestWebDtos,
        });
      }
    } catch (e) { }
  };
  formatHash = (file) => {
    const sha = CryptoJS.algo.SHA256.create();
    progressiveRead(
      file,
      (data) => {
        var wordArray = arrayBufferToWordArray(data);
        sha.update(wordArray);
      },
      (origin) => {
        const hash = sha.finalize().toString();
        this.checkHash(hash);
      }
    );
  };
  onCertBack = () => {
    this.setState({ showRes: false });
  };
  onFileSubmit = (fileObj) => {
    const { file } = fileObj;
    this.formatHash(file);
  };
  render() {
    const { qrcode } = this.state;
    const options = {
      activeClass: "active", // the class that is appended to the sections links
      anchors: [
        "index",
        "business",
        "platform1",
        "platform2",
        "certificates",
        "contact",
      ], // the anchors for each sections
      arrowNavigation: true, // use arrow keys
      delay: 1000, // the scroll animation speed
      navigation: true, // use dots navigatio
      verticalAlign: false, // align the content of each section vertical
      className: "SectionContainer",
      activeSection: 0,
      scrollCallback: (state) => {
        const sectionIndex = state.activeSection;
        this.timeout = setTimeout(() => {
          for (let i = 0; i < 6; i++) {
            const nodes = document.getElementsByClassName(`animated${i + 1}`);
            const nodeArr = [].slice.call(nodes);

            nodeArr.forEach((node) => {
              const style = node.getAttribute("style");
              const styleArr = style === null ? [] : `${style}`.split(";");
              if (styleArr.length === 0) {
                styleArr.push("display: block");
              }
              const res = styleArr.map((item) => {
                const itemArr = item.split(":");
                if (itemArr[0].trim() === "display") {
                  if (sectionIndex === i) {
                    if (styleArr.length > 1) {
                      itemArr[1] = "inline-block";
                    } else {
                      return null;
                    }
                  } else {
                    itemArr[1] = "none";
                  }
                }
                return `${itemArr[0]}: ${itemArr[1]}`;
              });
              if (res.filter((item) => item !== null).length !== 0) {
                node.setAttribute("style", res.join(";"));
              } else {
                node.removeAttribute("style");
              }
            });
          }
        }, 600);

        const navigation = document.getElementsByClassName("Navigation")[0];
        if (sectionIndex === 1 || sectionIndex === 2 || sectionIndex === 3) {
          navigation.setAttribute("class", "Navigation iconfont dim");
          this.setState({
            mode: "dim",
          });
        } else {
          navigation.setAttribute("class", "Navigation iconfont light");
          this.setState({
            mode: "light",
          });
        }
      },
    };

    return (
      <div className="officialContainer">
        <Header className="header">
          <div className="headerWrapper">
            <img src={logo} className="logo" alt="" />
            <div className="navList">
              <div className="anchorWrapper">
                <a id="index_anchor" href="#index">
                  首页
                </a>
              </div>
              <div className="anchorWrapper">
                <a href="#business">业务</a>
              </div>
              <div className="anchorWrapper">
                <a href="#platform1">平台</a>
              </div>
              <div
                className="anchorWrapper"
                style={{ visibility: "hidden", width: 0 }}
              >
                <a href="#platform2">平台2</a>
              </div>
              <div className="anchorWrapper" style={{ marginLeft: "-48px" }}>
                <a href="#certificates">出证</a>
              </div>
              <div className="anchorWrapper">
                <Link
                  to={{
                    pathname: `/cert`,
                  }}
                  className="cert"
                >
                  证据查验
                </Link>
              </div>
              <div className="anchorWrapper">
                <a href="#contact">联系</a>
              </div>
              <div>
                <a
                  className="extra"
                  href={`http://59.202.52.58/user/login`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  立即体验市监保
                </a>
              </div>
            </div>
          </div>
        </Header>
        <div className="bgImage" />
        <SectionsContainer {...options}>
          <Section>
            <div className="section1">
              <div className="bannerWrapper">
                <div className="banner bannerAnimation">
                  <img src={appBanner} className="banner" alt="" />
                  {qrcode && (
                    <QRCode className="qrcode" size={60} value={qrcode} />
                  )}
                </div>
                <img src={center} className="size bannerAnimation" alt="" />
              </div>
            </div>
          </Section>
          <Section>
            <div className="section2">
              <img
                src={business}
                className="business animation animated2 size"
                alt=""
                style={{ display: "none" }}
              />
            </div>
          </Section>
          <Section>
            <div className="section3">
              <img
                src={platform1}
                className="platfrom1 animation animated3 size"
                alt=""
                style={{ display: "none" }}
              />
            </div>
          </Section>
          <Section>
            <div className="section4">
              <img
                src={platform2}
                className="platfrom2 animation animated4 size"
                alt=""
                style={{ display: "none" }}
              />
            </div>
          </Section>
          <Section>
            <div className="section5">
              <img
                src={certificates}
                className="certificates animation animated5 size"
                alt=""
                style={{ display: "none" }}
              />
            </div>
          </Section>
          <Section>
            <div className="section6">
              <img
                src={hp_seq1}
                className="commonStyle hp_seq1 animated6"
                alt=""
                style={{ display: "none" }}
              />
              <div className="animated6" style={{ display: "none" }}>
                <div className="seqWrapper commonStyle">
                  <img
                    src={hp_seq2}
                    className=" hp_seq2 hp_seq2_animation delay2"
                    alt=""
                  />
                  <img
                    src={hp_seq3}
                    className=" hp_seq3 hp_seq3_animation delay3"
                    alt=""
                  />
                  <img
                    src={hp_seq4}
                    className=" hp_seq4 hp_seq4_animation delay4"
                    alt=""
                  />
                  <a
                    href="http://beian.miit.gov.cn"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="linkTo"
                  > </a>
                </div>
              </div>
            </div>
          </Section>
        </SectionsContainer>
      </div>
    );
  }
}

export default OfficialWebsite;
