module.exports = {
  firstSixHash:{
    "code":200,
    "data":{
      "blockNumber":"0x7a369",
      "chainTime":"2023-07-10T11:12:34",
      "createTime":"2023-07-10T11:11:59",
      "evidenceHash":"1adc651022c413ba8a6a3ef785ed1cd79dd0104caa397c87126c86ac5a1e0d75",
      "location":null,
      "person":"孙莹妮",
      "requestId":"B20230710581832",
      "txHash":"0x0f740b71c49f4acee8807b303445f0b127d0b154a7ab4dfc7ac7a806ead4072f",
      "urlArr":[
        "https://www.baidu.com/",
        "https://www.baidu.com/",
        "https://www.baidu.com/",
        "https://www.baidu.com/",
        "https://www.baidu.com/",
      ],
      "way":"WEB_PAGE"
    },
    "message":"成功"
  }
}
