import Jsencrypt from 'jsencrypt';
import { v4 as u } from 'uuid';
import axios from "axios";
import { fetchTimeDiff, saveTimeDiff } from './timeDiff';
import { getBaseUrl } from '../utils/hashTools';

// 安全加密
const f = (value) => {
  const arr = Array.from(value);
  return arr.reverse().join('');
};

const m = f('dIppa');
const n = f('erutangis');
const j = f('1modnar');
const h = f('bjs');
const p = `-----BEGIN PUBLIC KEY-----
MIICIjANBgkqhkiG9w0BAQEFAAOCAg8AMIICCgKCAgEAnfYCskLfTz27EQN3aHRoCEibtwYFy7nJ/aLY5HmFwiDMDI1tZo9EO24rOtvJBMhybr1k4tGua86I6Qayvfh6aUwDC4uN5oA1aQvZv4oCBbxZUQTS/BTG3uoSv/cLM6gTno0ZWq4L4SgG69f1CbBhGdsm4eqN6nRM6DFQx3mLoY988PWJEHrDmF5rvT4PNvNW9sHueCDR5mrgOSKfRoiKPNRlsqIcFP2fXKofONSiyHqQNeG44TyCtHHn060LPBxh24FJLS3NnvFPTwBKh3v2YT1kGs+E8uGyBVwrCHqSCo5eo9wMRjI69e4Zt+yThG5sSQaxzy+UJFvUUl/e6EO0lZMN8vrqDbIj4eEHaaB5b7NWIIZouumGMRJ1564j62f2sAOpvKFyhdYnTKEyrcZBwkzuvqHThVFYYRN3PiNHYp+aNPkYUyuFKclzYszoBTzaYsW7EKj69ZOwlwXNJxFOnFW5F9Lp8YG0kLpMX+fM3o8iReqj9r3inkhh8CR7qV0lP7yiGvxL43gAh4ln9W02HwBusq2GVqlb3n68iiL0vx3o2gFrJ8m7Cl015cPGJj0JI2IZ2CBcrlcFxrjURorXC8i1/NNxANz6BhQKlqncIUX00HjI+b1eYd29ZNzGdJRuDJoXMP7J0Ys/WXGMjOXXgizUt7NhHnSDxqBD3BoctDUCAwEAAQ==
-----END PUBLIC KEY-----`;

export const createApiKey = (diff = 0) => {
  const now = Date.now();
  const o = {
    now: now + diff,
    uuid: u(),
  };
  const cr = new Jsencrypt();
  cr.setPublicKey(p);
  const e = cr.encrypt(Object.values(o).join(';'));

  return {
    [m]: h,
    [n]: e,
    [j]: Math.random() * 1000,
  };
};

export const getApiKeys = () => createApiKey(fetchTimeDiff());


export const setTimeDiff = async () => {
  try {
    const res = await axios
      .get(`/v1/sign/timestamp/${Date.now()}/diff`, {
        baseURL: getBaseUrl(),
        headers: {
          os: 0,
        },
      })
    if (res && res.data.code === 200) {
      saveTimeDiff(res.data.data);
    }
  } catch (error) { }
};