import React, { Component } from "react";
import { message, Upload } from "antd";
import { FolderOpenOutlined } from "@ant-design/icons";
import "./index.less";
import "antd/lib/upload/style/index.css";
import "antd/lib/form/style/index.css";
import "antd/lib/message/style/index.css";

const { Dragger } = Upload;
class Index extends Component {
  state = {};

  handleFileSubmit(file) {
    if (file) {
      this.props.onSubmit(file);
      return;
    }
    message.error("请上传存证文件");
  }
  render() {
    return (
      <div className="main">
        <div className="formWrap">
          <Dragger
            style={{ width: 600, height: 180 }}
            maxCount={1}
            multiple={false}
            beforeUpload={(file, fileList) => {
              return new Promise((resolve, reject) => {
                if (file.name.slice(-4) === ".exe") {
                  message.error("不支持类型的文件");
                  return;
                }
                const isLt200M = file.size / 1024 / 1024 < 200;
                if (!isLt200M) {
                  return message.error("文件大小不能超过200MB!");
                }
                if (isLt200M) {
                  resolve();
                }
                resolve();
              });
            }}
            customRequest={async ({ file, filename, onSuccess, onError }) => {
              const isLt200M = file.size / 1024 / 1024 < 200;
              if (!isLt200M) {
                message.error("文件大小不能超过200MB!");
              }

              if (isLt200M) {
                const checkFile = {
                  file,
                  name: file.name,
                };
                this.handleFileSubmit(checkFile);
                onSuccess();
              } else {
                onError();
              }
            }}
            onRemove={async () => {
              return true;
            }}
          >
            <div className="uploadIcon">
              <FolderOpenOutlined style={{fontSize:28,color:'#335FFF'}}/>
            </div>
            <p className="text">点击或拖拽上传文件</p>
            <p className="uploadTips">请上传完整证据包文件，文件大小应不超过200M</p>
          </Dragger>
        </div>
      </div>
    );
  }
}
export default Index;
