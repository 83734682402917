import React, { Component } from "react";
import axios from "axios";
// import { message } from "antd";
// import { firstSixHash } from "../../utils/mock";
import { arrayBufferToWordArray, progressiveRead,getBaseUrl } from "../../utils/hashTools";
import CryptoJS from "crypto-js";
import { CertFileForm, CertFileRes } from "../../Components/CertFileForm";
import Header from "../../Components/Header";
import { getApiKeys } from "../../utils/requestSign";
import "./index.less";

class Cert extends Component {
  state = {
    showRes: false,
    checkRes: {},
  };

  checkHash = async (value) => {
    try {
      const { data } = await axios.get(`/mobile/verify/hash?hash=${value}`, {
        baseURL: getBaseUrl(),
        headers: {
          ...getApiKeys()
        },
      });
      // const data = firstSixHash;
      if (data.code !== 200) {
        this.setState({ checkRes: "error", showRes: true });
        return false;
      }
      this.setState({ checkRes: data.data, showRes: true });
    } catch (e) { }
  };
  formatHash = (file) => {
    const sha = CryptoJS.algo.SHA256.create();
    progressiveRead(
      file,
      (data) => {
        var wordArray = arrayBufferToWordArray(data);
        sha.update(wordArray);
      },
      (origin) => {
        const hash = sha.finalize().toString();
        this.checkHash(hash);
      }
    );
  };
  onCertBack = () => {
    this.setState({ showRes: false });
  };
  onFileSubmit = (fileObj) => {
    const { file } = fileObj;
    this.formatHash(file);
  };
  render() {
    const { checkRes } = this.state;
    return (
      <div className="cert-con">
        <div className="bg1"></div>
        <div className="bg2"></div>
        <Header></Header>

        {this.state.showRes ? (
          <>
            <CertFileRes onCertBack={this.onCertBack} checkRes={checkRes} />
          </>
        ) : (
          <div className="section7">
            <div className="container">
              <div className="cert-title1"></div>
              <div className="cert-title2"></div>
              <CertFileForm onSubmit={this.onFileSubmit} />
              <p className="cert-info">
                查验说明:
                <br />
                1.支持信监保证据查验
                <br />
                2.请解压证据总包，将总包文件中的证据包上传查验
              </p>
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default Cert;
