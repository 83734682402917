import HomePage from "./pages/Index";
import CertInspect from "./pages/Cert";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import React, { useEffect, useState } from "react";
import { setTimeDiff } from "./utils/requestSign";
import "./App1.less";

const componentGroup = [
  {
    path: "/",
    singleComponent: <HomePage />,
  },
  {
    path: "/cert",
    singleComponent: <CertInspect />,
  },
];
const isCustomRoot = !!process.env.ROOT;
export default function App() {
  const [hasDiff, setHasDiff] = useState(false);

  useEffect(() => {
    setTimeDiff().then(() => { setHasDiff(true) }).catch(() => { })
  }, [])

  return (
    <div className="App">
      <Router basename={isCustomRoot ?'/eemp/office' :''}>
        <Switch>
          {componentGroup.map((item) => {
            return (
              <Route path={item.path} exact>
                {hasDiff && item.singleComponent}
              </Route>
            );
          })}
          <Redirect to="/" />
        </Switch>
      </Router>
    </div>
  );
}
