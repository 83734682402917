import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";

import logo from "../../Assets/logo.png";
import "./index.less";

class Header extends Component {
  scrollToAnchor = (anchorName) => {
    sessionStorage.setItem("anchorName", anchorName);
    this.props.history.push("/");
    setTimeout(() => {
      if (!window.location.hash) {
        window.location.hash = anchorName;
      }
    }, 50);
    // window.location.href = window.location.origin;
  };

  render() {
    return (
      <div className="u-header">
        <div className="headerWrapper">
          <img src={logo} className="logo" alt="" />
          <div className="navList">
            <div className="anchorWrapper">
              <span onClick={() => this.scrollToAnchor("index")}>首页</span>
            </div>
            <div className="anchorWrapper">
              <span onClick={() => this.scrollToAnchor("business")}>业务</span>
            </div>
            <div className="anchorWrapper">
              <span onClick={() => this.scrollToAnchor("platform1")}>平台</span>
            </div>
            <div
              className="anchorWrapper"
              style={{ visibility: "hidden", width: 0 }}
            >
              <span onClick={() => this.scrollToAnchor("platform2")}>
                平台2
              </span>
            </div>
            <div className="anchorWrapper" style={{ marginLeft: "-48px" }}>
              <span onClick={() => this.scrollToAnchor("certificates")}>
                出证
              </span>
            </div>
            <div className="anchorWrapper active">
              <Link
                to={{
                  pathname: `/cert`,
                }}
                className="cert"
              >
                证据查验
              </Link>
            </div>
            <div className="anchorWrapper">
              <span onClick={() => this.scrollToAnchor("contact")}>联系</span>
            </div>
            <div>
              <a
                className="extra"
                href={`http://59.202.52.58/user/login`}
                target="_blank"
                rel="noopener noreferrer"
              >
                立即体验市监保
              </a>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(Header);
