import React, { Component } from "react";
import { Descriptions, Button } from "antd";
import CertFileFail from "./CertFileFail";
import {
  ArrowLeftOutlined,
  ArrowRightOutlined,
  CheckCircleFilled,
} from "@ant-design/icons";
import { EEvidenceTypeMap } from "./const";
import "./index.less";
import "antd/lib/descriptions/style/index.css";
class Index extends Component {
  state = {
    checkRes: {},
    time: "",
    idCode: "",
  };
  onCertBack = () => {
    this.props.onCertBack();
  };
  render() {
    const { checkRes = {} } = this.props;
    return (
      <div className="fullResult">
        {checkRes === "error" ? (
          <CertFileFail onBack={this.onCertBack} />
        ) : (
          <>
            <div className="backLink">
              <Button onClick={this.onCertBack} type="link">
                <ArrowLeftOutlined /> 继续查验
              </Button>
              <div className="card">
                <div className="succTips">
                  <CheckCircleFilled className="succIcon" />
                  该电子证据文件自形成时内容完整，未被篡改
                </div>
                <div style={{paddingLeft: 20}}>证据ID: {checkRes.requestId}</div>
                <div className="block">
                  <div className="title">证据信息</div>
                  <Descriptions column={1}>
                    <Descriptions.Item label="取证时间">
                      {checkRes.createTime}
                    </Descriptions.Item>
                    <Descriptions.Item label="取证人">
                      {checkRes.solidPerson}
                    </Descriptions.Item>
                    <Descriptions.Item label="取证平台">
                      信监保
                    </Descriptions.Item>
                    <Descriptions.Item label="取证类型">
                      {EEvidenceTypeMap[checkRes.way]}
                    </Descriptions.Item>
                    {checkRes.location && (
                      <Descriptions.Item label="地理位置">
                        {checkRes.location}
                      </Descriptions.Item>
                    )}
                    {checkRes.urlArr &&
                      checkRes.urlArr.map((dto, index) => (
                        <Descriptions.Item
                          key={dto}
                          label={`取证地址${index + 1}`}
                        >
                          {dto}
                        </Descriptions.Item>
                      ))}
                  </Descriptions>
                </div>
                <div className="block">
                  <div className="title">链上信息</div>
                  <Descriptions column={1}>
                    <Descriptions.Item label="区块号">
                      {checkRes.blockNumber}
                    </Descriptions.Item>
                    <Descriptions.Item label="所属区块链">
                      市监链
                    </Descriptions.Item>

                    <Descriptions.Item label="证据包哈希">
                      {checkRes.evidenceHash}
                    </Descriptions.Item>
                    <Descriptions.Item label="区块链哈希">
                      {checkRes.txHash}
                    </Descriptions.Item>
                    <Descriptions.Item label="上链时间">
                      {checkRes.chainTime}
                    </Descriptions.Item>
                  </Descriptions>
                </div>
              </div>
            </div>
            <div className="btn-con">
              <Button onClick={() => window.open('https://www.wetrustchain.com/cert')} type="link">
                点击前往信证链查验 <ArrowRightOutlined />
              </Button>
            </div>
          </>
        )}
      </div>
    );
  }
}

export default Index;
