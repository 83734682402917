import dayjs from 'dayjs';

const TIMEDIFF_KEY = 'timeDiff';

/** 记录与服务器时间差 */
export function saveTimeDiff(diff) {
  sessionStorage.setItem(TIMEDIFF_KEY, diff);
}

export function fetchTimeDiff() {
  const diff = Number(sessionStorage.getItem(TIMEDIFF_KEY));
  // eslint-disable-next-line
  return isNaN(diff) ? 0 : diff;
}

export function removeTimeDiff() {
  sessionStorage.removeItem(TIMEDIFF_KEY);
}

/** 获取当前服务器时间 */
export function fetchServeTime() {
  return dayjs().add(fetchTimeDiff(), 'millisecond');
}
