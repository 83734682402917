import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
// import OfficialWebsite from './App';
import App1 from "./App1";

ReactDOM.render(
  <>
    {/* <OfficialWebsite /> */}
    <App1 />
  </>,
  document.getElementById('root')
);

